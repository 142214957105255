import type { QueryResult } from '@apollo/client';
import type {
  GridListProps,
  ListVariantTypeAndProps
} from '@aurora/shared-client/components/common/List';
import { ListItemSpacing, ListVariant } from '@aurora/shared-client/components/common/List/enums';
import List from '@aurora/shared-client/components/common/List/List';
import ListTitle from '@aurora/shared-client/components/common/List/ListTitle';
import { PagerVariant } from '@aurora/shared-client/components/common/Pager/enums';
import Pager from '@aurora/shared-client/components/common/Pager/Pager';
import type { PagerVariantTypeAndProps } from '@aurora/shared-client/components/common/Pager/types';
import { PanelType } from '@aurora/shared-client/components/common/Panel/enums';
import QueryHandler from '@aurora/shared-client/components/common/QueryHandler/QueryHandler';
import PaginationHelper from '@aurora/shared-client/helpers/ui/PaginationHelper/PaginationHelper';
import useEndUserRoutes from '@aurora/shared-client/routes/useEndUserRoutes';
import { LoadingSize } from '@aurora/shared-client/types/enums';
import type { Connection } from '@aurora/shared-generated/types/graphql-schema-types';
import type { EndUserPages, EndUserQueryParams } from '@aurora/shared-types/pages/enums';
import React, { useContext, useRef } from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { getItems } from '../../../../helpers/list/EntityListHelper';
import getGridListColumns from '../../../../helpers/util/GridListColumnsHelper';
import type { AriaLiveRegions, ItemType } from '../../../../types/enums';
import EditContext from '../../../context/EditContext/EditContext';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../../entities/types';
import ItemView from '../../ItemView/ItemView';
import localStyles from './PaneledItemList.module.pcss';

export type CorePanelItemListSubHeaderProps = {
  /**
   * Applies panel type to the subheader component.
   */
  panel: PanelType;
};

/**
 * Props for `PaneledItemList`.
 */
interface Props<
  Item,
  TypeT extends ItemType,
  ViewVariantT extends ItemViewTypeAndProps<TypeT, ItemViewVariant<TypeT>>,
  TData,
  TVariables
> {
  /**
   * The query result from a GraphQL call.
   */
  queryResult: QueryResult<TData, TVariables>;
  /**
   * The size of the loading indicator.
   */
  loadingSize?: LoadingSize;
  /**
   * The variant display style used when rendering the message list.
   */
  listVariant?: ListVariantTypeAndProps<Item>;
  /**
   * The style of pager to use.
   */
  pagerVariant?: PagerVariantTypeAndProps<EndUserPages, EndUserQueryParams>;
  /**
   * The number of items to show per page.
   */
  pageSize?: number;
  /**
   * The path to the items in the query results. This is used to get the items from
   * the query result for display and used for updating the items during pagination.
   * If left unspecified then the item path is assumed to simply be the key of the first
   * property inside the `data` object.
   */
  itemPath?: string;
  /**
   * Callback after pagination has occurred, can be used to augment API results
   * to "link" them to existing objects in the Apollo cache.
   */
  onUpdate?: ((newConnection: Connection, fetchMoreResult: TData) => TData) | undefined;
  /**
   * Callback function to fetch current page state
   */
  onPageChange?: (newConnection: Connection, variables: TVariables) => void;
  /**
   * Class name(s) to apply to the component element.
   */
  className?: string;
  /**
   * A component to display when the list is empty.
   */
  empty: React.FC<React.PropsWithChildren<unknown>>;
  /**
   * Applies panel styling to the component.
   */
  panel?: PanelType;
  /**
   * The path to the sub object in the query result, from where the items should
   * be taken. Used when the items to display are not part of the parent Node object.
   */
  querySubObject?: string;
  /**
   * Header specification for the entity list panel.
   */
  header?: React.FC<React.PropsWithChildren<unknown>> | string;
  /**
   * Footer specification for the entity list panel.
   */
  footer?: React.FC<React.PropsWithChildren<unknown>> | string;
  /**
   * Class name for the panel header section.
   */
  headerClassName?: string;
  /**
   * Subheader component.
   */
  subHeader?: React.FC<CorePanelItemListSubHeaderProps>;
  /**
   * Class name for the panel body section.
   */
  bodyClassName?: string;
  /**
   * Class name for the panel footer section.
   */
  footerClassName?: string;
  /**
   * Whether to display footer or not. This is required because by default footer is displayed for pagination.
   */
  useFooter?: boolean;
  /**
   * Whether to display header or not when there is no items to display. When set to true will display the header even
   * when there are no items to display.
   */
  useHeaderWhenEmpty?: boolean;
  /**
   * Whether to show the header and sub header while loading.
   */
  showHeadersWhileLoading?: boolean;
  /**
   * Whether to apply the sr-only tag to the header @link https://getbootstrap.com/docs/4.0/utilities/screenreaders/
   */
  isHeaderSrOnly?: boolean;
  /**
   * Whether to display empty state or not.
   * When set to true, empty state is displayed only when there is no content to show.
   * If set to false, empty state is never displayed.
   */
  useEmpty?: boolean;
  /**
   * View variant type and props for the entity of type EntityT.
   */
  variant: ViewVariantT;
  /**
   * Item type for entity of type EntityT.
   */
  type: TypeT;
  /**
   * For testing purposes only.
   */
  id?: string;
  /**
   * Aria live regions
   */
  ariaLiveRegions?: AriaLiveRegions;

  role?: string;
}

/**
 * Creates a paneled `List` from a `QueryResult` that contains an optional header, and a footer. The list is rendered
 * based on the specified panel type.Each panel section are rendered within its wrapping div. This should be used for
 * all Paneled View of list or any other view of list that desires a sectioned header, body and a footer.
 * If a sectioned body and a footer is not desired then use ItemList.
 * The general markup generated from this list:
 * <code>
 *   <article class="lia-panel-list-{type}">
 *     <header class="lia-panel-list-header">Header</header>
 *     // Optional `PaneledItemListSubHeader`
 *     <section class="lia-panel-list-body">
 *       <ul>
 *          <li><ItemView of EntityT</li>
 *          <li><ItemView of EntityT</li>
 *          <li><ItemView of EntityT</li>
 *          <li><ItemView of EntityT</li>
 *          <li><ItemView of EntityT</li>
 *          <li><ItemView of EntityT</li>
 *        </ul>
 *     </section>
 *     <footer class="lia-panel-list-footer>Footer or Pager</footer>
 *   </article>
 * </code>
 * @author Manish Shrestha, Willi Hyde
 */
const PaneledItemList = <
  EntityT,
  TypeT extends ItemType,
  ViewVariantT extends ItemViewTypeAndProps<TypeT, ItemViewVariant<TypeT>>,
  TData,
  TVariables
>({
  queryResult,
  loadingSize = LoadingSize.LG,
  listVariant,
  pagerVariant = { type: PagerVariant.LOAD_MORE },
  pageSize = 5,
  itemPath,
  onUpdate,
  onPageChange,
  className,
  empty: Empty,
  panel = PanelType.STANDARD,
  querySubObject,
  useFooter = true,
  header: Header,
  footer: Footer,
  headerClassName,
  subHeader: SubHeader,
  bodyClassName,
  footerClassName,
  variant,
  type,
  useHeaderWhenEmpty = true,
  showHeadersWhileLoading = false,
  isHeaderSrOnly = false,
  useEmpty = false,
  id,
  ariaLiveRegions,
  role
}: Props<EntityT, TypeT, ViewVariantT, TData, TVariables>): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const ref = useRef<HTMLElement>(null);
  const { Link, loading: routesLoading } = useEndUserRoutes();

  const paginationHelper = PaginationHelper.fromQueryResult<TData, TVariables>(
    queryResult,
    pageSize,
    itemPath,
    onUpdate,
    onPageChange
  );

  const panelTypeToListGroupMap: Record<PanelType, ListVariantTypeAndProps<EntityT>> = {
    [PanelType.STANDARD]: {
      type: ListVariant.UNSTYLED
    },
    [PanelType.DIVIDER]: {
      type: ListVariant.LIST_GROUP
    },
    [PanelType.BUBBLE]: {
      type: ListVariant.UNSTYLED,
      props: {
        listItemSpacing: ListItemSpacing.MD
      }
    },
    [PanelType.SPACED]: {
      type: ListVariant.UNSTYLED,
      props: {
        listItemSpacing: ListItemSpacing.MD
      }
    },
    [PanelType.NONE]: { type: ListVariant.UNSTYLED }
  };

  /**
   * renders the entity.
   * @param entity the entity to render.
   */
  function renderItem(entity: EntityT): React.ReactElement {
    return <ItemView<EntityT, TypeT, ViewVariantT> entity={entity} variant={variant} type={type} />;
  }

  const FinalHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
    if (!Header) {
      return null;
    }

    return typeof Header === 'string' ? (
      <ListTitle className={cx('lia-panel-list-title')}>{Header}</ListTitle>
    ) : (
      <Header />
    );
  };

  function getDateTestId() {
    return id ? `PanelItemList.${id}` : 'PanelItemList';
  }

  const hasNextPage: boolean = paginationHelper?.pageInfo?.hasNextPage;
  const hasPreviousPage: boolean = paginationHelper?.pageInfo?.hasPreviousPage;
  const isPageable: boolean =
    pagerVariant.type === PagerVariant.PREVIOUS_NEXT ||
    pagerVariant.type === PagerVariant.PREVIOUS_NEXT_LINKABLE
      ? hasNextPage || hasPreviousPage
      : hasNextPage;

  const DefaultFooter: React.FC<React.PropsWithChildren<unknown>> = () => {
    let finalPagerVariant = pagerVariant;

    if (pagerVariant.type === PagerVariant.SEE_ALL_MODAL) {
      finalPagerVariant = {
        type: pagerVariant.type,
        props: {
          listVariant,
          ...pagerVariant.props,
          Link
        }
      };
    }

    if (isPageable) {
      return (
        <Pager
          loadPage={paginationHelper.loadPage}
          pageInfo={paginationHelper.pageInfo}
          variant={finalPagerVariant}
        />
      );
    }

    return null;
  };

  const FinalFooter = Footer !== undefined ? Footer : DefaultFooter;
  const { showEditControls } = useContext(EditContext);

  if (routesLoading) {
    return null;
  }

  return (
    <QueryHandler<TData, TVariables>
      queryResult={queryResult}
      loadingSize={loadingSize}
      loadingWrapStyles={{ height: `${ref.current?.offsetHeight}px` }}
      loadingWrapClassName={cx('lia-panel-list-body', bodyClassName)}
      useFullLoadingHeight={false}
      showChildrenWhileLoading={showHeadersWhileLoading}
    >
      {({ isLoading, Loading }): React.ReactNode => {
        const items: EntityT[] = getItems(queryResult, itemPath, querySubObject);

        const hasItems = items?.length > 0;
        const showHeader =
          Header &&
          (useHeaderWhenEmpty || hasItems || (showHeadersWhileLoading && isLoading) || !isLoading);
        const showSubHeader = SubHeader && ((showHeadersWhileLoading && isLoading) || !isLoading);

        const useEmptyState = !hasItems && (useEmpty || (showEditControls && !isLoading));

        const showFooter: boolean =
          !useEmptyState && useFooter && FinalFooter && hasItems && isPageable && !isLoading;

        const listVariantOrDefault: ListVariantTypeAndProps<EntityT> =
          listVariant ?? panelTypeToListGroupMap[panel];

        const decoratedListVariant = {
          ...listVariantOrDefault,
          props: {
            ...listVariantOrDefault?.props,
            listItemClassName: (item: EntityT): string =>
              cx('lia-panel-list-item', listVariantOrDefault?.props?.listItemClassName?.(item))
          }
        };

        // Sets the grid column props based on the min value between page size and number of items from result.
        if (decoratedListVariant.type === ListVariant.GRID) {
          const size = Math.min(items?.length, pageSize);
          (decoratedListVariant.props as GridListProps<EntityT>).colProps =
            getGridListColumns(size);
        }

        return (
          (hasItems || useEmptyState || showHeadersWhileLoading) && (
            <article
              className={cx(
                className,
                { [`lia-panel-list-${panel}`]: showHeader || Empty || FinalFooter },
                { 'text-body': showHeader || Empty || FinalFooter }
              )}
              data-testid={
                typeof Header === 'string'
                  ? `PanelItemList.${Header}`.replaceAll(/[^.A-Za-z]/g, '')
                  : getDateTestId()
              }
            >
              {showHeader && (
                <header
                  className={cx(
                    { 'lia-panel-list-header': !isHeaderSrOnly },
                    { 'lia-has-sub-header': showSubHeader },
                    { 'sr-only': isHeaderSrOnly },
                    headerClassName
                  )}
                >
                  <FinalHeader />
                </header>
              )}
              {showSubHeader && SubHeader({ panel: panel })}
              {isLoading && showHeadersWhileLoading && <Loading />}
              {!useEmptyState && !isLoading && (
                <section
                  ref={ref}
                  className={cx(
                    'lia-panel-list-body',
                    { 'lia-has-footer': showFooter },
                    bodyClassName
                  )}
                  role={role}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(ariaLiveRegions && { 'aria-live': ariaLiveRegions })}
                >
                  <List<EntityT>
                    items={items}
                    variant={decoratedListVariant as ListVariantTypeAndProps<EntityT>}
                  >
                    {renderItem}
                  </List>
                </section>
              )}
              {useEmptyState && !isLoading && (
                <section
                  className={cx(
                    'lia-panel-list-body lia-panel-list-body-empty',
                    { 'lia-has-footer': showFooter },
                    bodyClassName
                  )}
                >
                  <Empty />
                </section>
              )}
              {showFooter && !isLoading && (
                <footer
                  data-testid="PanelItemList.Footer"
                  className={cx('lia-panel-list-footer', footerClassName)}
                >
                  <FinalFooter />
                </footer>
              )}
            </article>
          )
        );
      }}
    </QueryHandler>
  );
};

export default PaneledItemList;
